'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.devices.factory:DeviceTypeManager

 # @description

###
angular
  .module 'mundoAdmin.devices'
  .factory 'DeviceTypeManager', [
    'MundoDefaultManager'
    'Restangular'
    '$q'
    '_'
    '$log'
    (
      MundoDefaultManager
      Restangular
      $q
      _
      $log
    ) ->
      DeviceTypeManagerBase = new MundoDefaultManager()

      DeviceTypeManagerBase.setUrl('devices/types')
      DeviceTypeManagerBase.setActions(['list', 'print'])
      DeviceTypeManagerBase.setSearchFields(['label', 'model', 'code'])

      DeviceTypeManagerBase.getListCustom = ->
        types = []

        Restangular.all(@conf.url).customGET("").then((data) ->
          $log.debug 'data,', data
          for k, v of data.results
            types.push(v)
          types
        )
        types

      DeviceTypeManagerBase.getListPromise = ->
        return Restangular.all(@conf.url).customGET("")

      DeviceTypeManagerBase.getList = ->
        response = $q.defer()
        response.resolve @getListCustom()
        response.promise

      DeviceTypeManagerBase.getProfilesByType = (typeCode) ->
        profiles = []
        Restangular.all(@conf.url).customGET('').then((data) ->
          currentType = _.findWhere data.results, {code: typeCode}
          for k, v of currentType.profiles
            profiles.push(v)
          profiles
        )
        profiles

      DeviceTypeManagerBase.getListSchema = ->
        [
          key: 'model'
          title: 'Model'
  #        sort: 'model'
        ,
          key: 'label'
          title: 'Label'
  #        sort: 'label'
        ,
          key: 'code'
          title: 'Code'
  #        sort: 'code'
        ]
      DeviceTypeManagerBase
  ]
